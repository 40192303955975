import React from 'react';

interface Props {
  isMain: boolean;
  text: string;
  id?: string;
}

const Heading: React.FC<Props> = (props: Props) => {
  let type = 'long';
  if (!props.isMain) {
    type = 'short';
  }
  return (
    <div id={props.id} className={'heading heading' + type + ' text-center'}>
      <h2 className='heading-text'>{props.text}</h2>
    </div>
  );
};

export default Heading;
