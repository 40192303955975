import { Container, Row, Col } from 'react-bootstrap';

export default function InfoBox(): JSX.Element {
  return (
    <Container className='mt-50'>
      <Row>
        <Col>
          <p>
            VUZ Slovakia, s.r.o. je 100% dcérskou spoločnosťou Výzkumného Ústavu
            Železničního, a.s. (VUZ). Ponúkame vysoko špecializované služby a
            komplexné riešenia v oblasti posudzovania a certifikácie
            železničných vozidiel a všetkých štrukturálnych subsystémov
            železničnej dopravy. Radi vám zabezpečíme nielen všetky činnosti,
            ktoré poskytuje VUZ, s výhodami sídla v Bratislave, ale aj ďalšie
            činnosti v oblasti výskumu a vývoja v doprave, prieskumu trhu,
            spracovania dát či poradenskej činnosti pri spracúvaní projektov na
            čerpanie finančných zdrojov EÚ.
          </p>
          <p>
            <span className='special-marker'>
              Skupina VUZ ponúka služby NoBo, DeBo (CZ a SK), AsBo, ISA a
              certifikácie ECM.
            </span>
          </p>
          <p>
            VUZ je autorizovaným orgánom č. AO 258 a notifikovaným orgánom č.
            1714, ktorý posudzuje štrukturálne subsystémy infraštruktúra,
            energia, koľajové vozidlá, traťové a palubné riadenie a zabezpečenie
            a to vrátane príslušných prvkov interoperability v železničnom
            systéme Európskej únie.
          </p>
          <p className='special-marker'>
            COV VUZ SK je akreditovaným orgánom na základe rozhodnutia SNAS
            právnickou osobou poverenou k posudzovaniu zhody subsystémov
            železničného systému s vnútroštátnymi predpismi Slovenskej republiky
            – DeBo.
          </p>
          <p>
            VUZ Slovakia, s.r.o. tak prináša najmä na slovenský trh bohaté
            skúsenosti, vysokú kvalitu a lepšiu dostupnosť služieb VUZ,
            európskeho lídra v oblasti železničného testovania a certifikácie.
            <br />
            <br />
            Pre novú pobočku hľadáme odborných pracovníkov so znalosťami v
            oblasti železničného odboru. Záujemcov prosíme, aby poslali svoj
            životopis na adresu konateľov.
          </p>
          <p>Dokumenty k stiahnutiu</p>
          <ul>
            <li>
              <a
                target='_blank'
                title='Postup podávania odvolaní a sťažností (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/20231201_Postup_podavania_odvolani_a_staznosti_II_cbefcb360d.pdf'
                rel='noreferrer'
              >
                Postup podávania odvolaní a sťažností (PDF)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Produktový list (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/VUZ_SK_PL_FINAL_TISK_DATA_334ba3347f.pdf'
                rel='noreferrer'
              >
                Produktový list (PDF)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Podmienky pre certifikáciu (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/20231206_Podminky_pro_certifikaci_0ac695dc7f.pdf'
                rel='noreferrer'
              >
                Podmienky pre certifikáciu (PDF)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Osvedčenie o akreditácii (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/P_058_Osvedcenie_o_akreditacii_VUZ_SK_CZ_a_EN_e2b4bd70f5.pdf'
                rel='noreferrer'
              >
                Osvedčenie o akreditácii (PDF)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Rozhodnutie o o akreditácii (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/ROZHODNUTIE_O_AKREDITACII_P_058_20231026_R_vuz_slovakia_740cacbc7e.pdf'
                rel='noreferrer'
              >
                Rozhodnutie o o akreditácii (PDF)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Rozsah akreditácie (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/P_058_20231026_P_vuz_slovakia_SK_a4457e5db4.pdf'
                rel='noreferrer'
              >
                Rozsah akreditácie (PDF)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Rozhodnutie o poverení právnickej osoby posudzovaním zhody subsystémov s vnútroštátnymi predpismi (PDF)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/20250914_Rozhodnutie_MDV_De_Bo_5262285d94.pdf'
                rel='noreferrer'
              >
                Rozhodnutie o poverení právnickej osoby posudzovaním zhody
                subsystémov s vnútroštátnymi predpismi (PDF)
              </a>
            </li>
          </ul>
          <p>Certifikačné dokumenty k stiahnutiu</p>
          <ul>
            <li>
              <a
                target='_blank'
                title='Ziadost o posudenie zhody s technickymi poziadavkami (Doc)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/F006_ZD_01_Ziadost_oposudenie_zhody_stechnickymi_poziadavkami_ecb17484fa.docx'
                rel='noreferrer'
              >
                Ziadost o posudenie zhody s technickymi poziadavkami (Doc)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Ziadost o Osvedcenie zhody subjektu zodpovedneho za udrzbu (Doc)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/F012_ZU_01_Ziadost_o_Osvedcenie_zhody_subjektu_zodpovedneho_za_udrzbu_9e54773bbf.docx'
                rel='noreferrer'
              >
                Ziadost o Osvedcenie zhody subjektu zodpovedneho za udrzbu (Doc)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Ziadost o Osvedcenie o zhode pre funkcie udrzby (Doc)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/F013_ZF_01_Ziadost_o_Osvedcenie_o_zhode_pre_funkcie_udrzby_14aceb94a1.docx'
                rel='noreferrer'
              >
                Ziadost o Osvedcenie o zhode pre funkcie udrzby (Doc)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Dotaznik rozsahu cinnosti funkcii udrzby (Doc)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/F031_DF_01_Dotaznik_rozsahu_cinnosti_funkcii_udrzby_64661fbe39.docx'
                rel='noreferrer'
              >
                Dotaznik rozsahu cinnosti funkcii udrzby (Doc)
              </a>
            </li>
            <li>
              <a
                target='_blank'
                title='Dotaznik rozsahu cinnosti subjektu zodpovedneho za udrzbu (Doc)'
                href='https://ys-common.s3.eu-central-1.amazonaws.com/F030_DU_01_Dotaznik_rozsahu_cinnosti_subjektu_zodpovedneho_za_udrzbu_7c2ced23b8.docx'
                rel='noreferrer'
              >
                Dotaznik rozsahu cinnosti subjektu zodpovedneho za udrzbu (Doc)
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
