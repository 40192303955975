import { useEffect, useState } from 'react';

interface hookProps {
  data: newsTypes[] | newsTypes | null | undefined;
  error: unknown | null;
  loading: boolean;
}

interface newsTypes {
  id: number;
  published: Date;
  lang: langTypes[];
  image: imageTypes;
  gallery: galleryTypes[];
}
interface langTypes {
  id: number;
  title: string;
  content: string;
  lang: string;
}
interface imageTypes {
  id: number;
  name: string;
  alternativeText: string;
  url: string;
}

interface galleryTypes {
  id: number;
  name: string;
  alternativeText: string;
  url: string;
  formats: {
    thumbnail: {
      name: string;
      url: string;
    };
  };
}

const useFetch = (uri: string): hookProps => {
  const [data, setData] = useState<newsTypes[] | newsTypes | null>();
  const [error, setError] = useState<null | unknown>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(uri);
        const json = await res.json();
        setData(json);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    };
    fetchData();
  }, [uri]);
  return { data, error, loading };
};

export default useFetch;

/*
[
  {
     "id":1,
     "published":"2022-02-18",
     "title":null,
     "storyCategory":null,
     "published_at":"2022-02-18T11:33:19.740Z",
     "created_at":"2022-02-18T11:33:15.520Z",
     "updated_at":"2022-02-18T11:33:19.804Z",
     "lang":[
        {
           "id":111,
           "title":"Test story",
           "content":"<p>Lorem ipsum body</p>\n",
           "lang":"sk",
           "perex":"Perex lorem ipsum"
        }
     ],
     "image":{
        "id":805,
        "name":"IMG_1106_e619421a3b.jpeg",
        "alternativeText":"",
        "caption":"",
        "width":1280,
        "height":960,
        "formats":{
           "large":{
              "ext":".jpeg",
              "url":"https://ys-common.s3.eu-central-1.amazonaws.com/large_IMG_1106_e619421a3b_9ee91512ed.jpeg",
              "hash":"large_IMG_1106_e619421a3b_9ee91512ed",
              "mime":"image/jpeg",
              "name":"large_IMG_1106_e619421a3b.jpeg",
              "path":null,
              "size":148.55,
              "width":1000,
              "height":750
           },
           "small":{
              "ext":".jpeg",
              "url":"https://ys-common.s3.eu-central-1.amazonaws.com/small_IMG_1106_e619421a3b_9ee91512ed.jpeg",
              "hash":"small_IMG_1106_e619421a3b_9ee91512ed",
              "mime":"image/jpeg",
              "name":"small_IMG_1106_e619421a3b.jpeg",
              "path":null,
              "size":43.12,
              "width":500,
              "height":375
           },
           "medium":{
              "ext":".jpeg",
              "url":"https://ys-common.s3.eu-central-1.amazonaws.com/medium_IMG_1106_e619421a3b_9ee91512ed.jpeg",
              "hash":"medium_IMG_1106_e619421a3b_9ee91512ed",
              "mime":"image/jpeg",
              "name":"medium_IMG_1106_e619421a3b.jpeg",
              "path":null,
              "size":90.49,
              "width":750,
              "height":563
           },
           "thumbnail":{
              "ext":".jpeg",
              "url":"https://ys-common.s3.eu-central-1.amazonaws.com/thumbnail_IMG_1106_e619421a3b_9ee91512ed.jpeg",
              "hash":"thumbnail_IMG_1106_e619421a3b_9ee91512ed",
              "mime":"image/jpeg",
              "name":"thumbnail_IMG_1106_e619421a3b.jpeg",
              "path":null,
              "size":8.87,
              "width":208,
              "height":156
           }
        },
        "hash":"IMG_1106_e619421a3b_9ee91512ed",
        "ext":".jpeg",
        "mime":"image/jpeg",
        "size":231.45,
        "url":"https://ys-common.s3.eu-central-1.amazonaws.com/IMG_1106_e619421a3b_9ee91512ed.jpeg",
        "previewUrl":null,
        "provider":"aws-s3",
        "provider_metadata":null,
        "created_at":"2021-07-28T13:19:36.844Z",
        "updated_at":"2021-07-28T13:19:36.881Z"
     },
      "gallery":[
         {
            "id":805,
            "name":"IMG_1106_e619421a3b.jpeg",
            "alternativeText":"",
            "caption":"",
            "width":4608,
            "height":3456,
            "formats":{
               "large":{
                  "ext":".jpeg",
                  "url":"https://ys-common.s3.eu-central-1.amazonaws.com/large_IMG_1106_e619421a3b_9ee91512ed.jpeg",
                  "hash":"large_IMG_1106_e619421a3b_9ee91512ed",
                  "mime":"image/jpeg",
                  "name":"large_IMG_1106_e619421a3b.jpeg",
                  "path":null,
                  "size":72.9,
                  "width":1000,
                  "height":750
               },
               "small":{
                  "ext":".jpeg",
                  "url":"https://ys-common.s3.eu-central-1.amazonaws.com/small_IMG_1106_e619421a3b_9ee91512ed.jpeg",
                  "hash":"small_IMG_1106_e619421a3b_9ee91512ed",
                  "mime":"image/jpeg",
                  "name":"small_IMG_1106_e619421a3b.jpeg",
                  "path":null,
                  "size":26.14,
                  "width":500,
                  "height":375
               },
               "medium":{
                  "ext":".jpeg",
                  "url":"https://ys-common.s3.eu-central-1.amazonaws.com/medium_IMG_1106_e619421a3b_9ee91512ed.jpeg",
                  "hash":"medium_IMG_1106_e619421a3b_9ee91512ed",
                  "mime":"image/jpeg",
                  "name":"medium_IMG_1106_e619421a3b.jpeg",
                  "path":null,
                  "size":47.15,
                  "width":750,
                  "height":563
               },
               "thumbnail":{
                  "ext":".jpeg",
                  "url":"https://ys-common.s3.eu-central-1.amazonaws.com/thumbnail_IMG_1106_e619421a3b_9ee91512ed.jpeg",
                  "hash":"thumbnail_IMG_1106_e619421a3b_9ee91512ed",
                  "mime":"image/jpeg",
                  "name":"thumbnail_IMG_1106_e619421a3b.jpeg",
                  "path":null,
                  "size":7.87,
                  "width":208,
                  "height":156
               }
            },
            "hash":"IMG_1106_e619421a3b_9ee91512ed",
            "ext":".jpeg",
            "mime":"image/jpeg",
            "size":1728.43,
            "url":"https://ys-common.s3.eu-central-1.amazonaws.com/IMG_1106_e619421a3b_9ee91512ed.jpeg",
            "previewUrl":null,
            "provider":"aws-s3",
            "provider_metadata":null,
            "created_at":"2021-07-28T13:19:36.844Z",
            "updated_at":"2022-02-23T09:59:36.335Z"
         },
         {
            "id":803,
            "name":"liza-2-1229.jpg",
            "alternativeText":"",
            "caption":"",
            "width":400,
            "height":600,
            "formats":{
               "small":{
                  "ext":".jpg",
                  "url":"https://ys-common.s3.eu-central-1.amazonaws.com/small_liza_2_1229_d3b5aa91dd.jpg",
                  "hash":"small_liza_2_1229_d3b5aa91dd",
                  "mime":"image/jpeg",
                  "name":"small_liza-2-1229.jpg",
                  "path":null,
                  "size":29.25,
                  "width":333,
                  "height":500
               },
               "thumbnail":{
                  "ext":".jpg",
                  "url":"https://ys-common.s3.eu-central-1.amazonaws.com/thumbnail_liza_2_1229_d3b5aa91dd.jpg",
                  "hash":"thumbnail_liza_2_1229_d3b5aa91dd",
                  "mime":"image/jpeg",
                  "name":"thumbnail_liza-2-1229.jpg",
                  "path":null,
                  "size":3.99,
                  "width":104,
                  "height":156
               }
            },
            "hash":"liza_2_1229_d3b5aa91dd",
            "ext":".jpg",
            "mime":"image/jpeg",
            "size":29.53,
            "url":"https://ys-common.s3.eu-central-1.amazonaws.com/liza_2_1229_d3b5aa91dd.jpg",
            "previewUrl":null,
            "provider":"aws-s3",
            "provider_metadata":null,
            "created_at":"2020-12-11T12:40:59.045Z",
            "updated_at":"2020-12-11T12:40:59.056Z"
         }
      ]
   }
]
*/
