import { Box, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function PageUp(): JSX.Element {
  return (
    <Box className='pageup' sx={{ '& > :not(style)': { m: 1 } }}>
      <a href='#home'>
        <Fab color='secondary' aria-label='add'>
          <KeyboardArrowUpIcon />
        </Fab>
      </a>
    </Box>
  );
}
