import { Navbar, Container, Nav } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Logo_vuz_sk from '../img/logo-vuz-sk.svg';

export default function Menu(): JSX.Element {
  return (
    <Navbar
      id='home'
      className='imgbg mainmenu py-5 navbar-dark'
      variant='dark'
      expand='md'
    >
      <Container>
        <Navbar.Brand>
          <Link to='/'>
            <img
              src={Logo_vuz_sk}
              width='140px'
              height='85px'
              className='d-inline-block align-top'
              alt='Logo vuz SK'
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav activeKey='#home' className='ms-auto p-3'>
            <Link className='nav-link' smooth to='/#contact'>
              Kontakty
            </Link>
            <Link className='nav-link' smooth to='/#message'>
              Napíšte nám
            </Link>
            <Link className='nav-link' smooth to='/news#new'>
              Novinky
            </Link>
            <Link className='nav-link' smooth to='/career#new'>
              Kariéra
            </Link>
            <Nav.Link
              href='https://www.cdvuz.cz/'
              rel='noreferrer'
              target='_blank'
            >
              vuz.cz
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
