import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, TextField } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

const ContactForm: React.FC = () => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const initialValues: { [key: string]: string } = {
    first_name: '',
    last_name: '',
    email: '',
    subject: '',
    message: '',
  };

  const isValidInitValues: { [key: string]: boolean } = {
    first_name: false,
    last_name: false,
    email: false,
    subject: false,
    message: false,
  };

  const errmsg = 'Zle vyplnené polia';

  const [values, setValues] = useState(initialValues);
  const [isInvalidValues, setIsInvalidValues] = useState(isValidInitValues);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [key, setKey] = useState<string | null>(null);
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    setPending(true);
    fetch('/api/v1/recaptchaKey')
      .then((r) => r.text())
      .then(setKey)
      .finally(() => {
        setPending(false);
      });
  }, []);

  const resetValues = () => {
    setValues(initialValues);
    setIsInvalidValues(isValidInitValues);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      [id]: value,
    });
    if (value.trim()) {
      setIsInvalidValues({
        ...isInvalidValues,
        [id]: false,
      });
    }
  };

  const handleBlurInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { id, value } = e.target;
    if (!value.trim()) {
      setIsInvalidValues({
        ...isInvalidValues,
        [id]: true,
      });
    }
  };

  const validate = () => {
    const valuesState = { ...isInvalidValues };
    Object.entries(values).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(valuesState, key)) {
        valuesState[key] = value.trim() === '';
      }
      return false;
    });
    setIsInvalidValues(valuesState);
    const isValid: boolean = Object.values(valuesState).some(function (item) {
      return item === true;
    });
    return !isValid;
  };

  const hanldeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      const ref = recaptchaRef;
      if (ref && ref.current) {
        const current = ref.current;
        if (current) {
          setPending(true);
          const token = await current.executeAsync();
          try {
            const resp = await fetch('/api/v1/send', {
              method: 'POST',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify({
                email: values.email.trim(),
                first_name: values.first_name.trim(),
                last_name: values.last_name.trim(),
                subject: values.subject.trim(),
                message: values.message.trim(),
                'g-recaptcha-response': token,
              }),
            });
            if (resp.ok) {
              // setValues(initialValues);
            } else {
              // Nothing
            }
          } catch (e) {
            // Catched
          }
          setShowSuccess(true);
          setPending(false);
          resetValues();
        }
      }
    } else {
      // Nothing
    }
  };

  return (
    <form onSubmit={hanldeSubmit}>
      {key && <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey={key} />}
      <Row>
        <Col>
          <TextField
            fullWidth
            id='first_name'
            value={values.first_name}
            onChange={handleInputChange}
            label='Krstné meno*'
            variant='outlined'
            disabled={pending}
            onBlur={handleBlurInput}
            error={isInvalidValues.first_name}
            helperText={
              isInvalidValues.first_name
                ? errmsg
                : values.first_name.length > 10 &&
                  `${values.first_name.length}/20`
            }
            inputProps={{
              maxLength: '20',
            }}
          />
        </Col>
        <Col>
          <TextField
            fullWidth
            id='last_name'
            value={values.last_name}
            onChange={handleInputChange}
            label='Priezvisko*'
            variant='outlined'
            disabled={pending}
            onBlur={handleBlurInput}
            error={isInvalidValues.last_name}
            helperText={
              isInvalidValues.last_name
                ? errmsg
                : values.last_name.length > 10 &&
                  `${values.last_name.length}/20`
            }
            inputProps={{
              maxLength: '20',
            }}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <TextField
            fullWidth
            id='email'
            type='email'
            value={values.email}
            onChange={handleInputChange}
            label='Email*'
            variant='outlined'
            disabled={pending}
            onBlur={handleBlurInput}
            error={isInvalidValues.email}
            helperText={
              isInvalidValues.email
                ? errmsg
                : values.email.length > 20 && `${values.email.length}/50`
            }
            inputProps={{
              maxLength: '50',
            }}
          />
        </Col>
        <Col>
          <TextField
            fullWidth
            id='subject'
            value={values.subject}
            onChange={handleInputChange}
            label='Predmet*'
            variant='outlined'
            disabled={pending}
            onBlur={handleBlurInput}
            error={isInvalidValues.subject}
            helperText={
              isInvalidValues.subject
                ? errmsg
                : values.subject.length > 20 && `${values.subject.length}/50`
            }
            inputProps={{
              maxLength: '50',
            }}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <TextField
            fullWidth
            id='message'
            multiline
            rows={6}
            value={values.message}
            onChange={handleInputChange}
            label=''
            variant='outlined'
            disabled={pending}
            onBlur={handleBlurInput}
            error={isInvalidValues.message}
            helperText={
              isInvalidValues.message
                ? errmsg
                : values.message.length > 100 && `${values.message.length}/1000`
            }
            inputProps={{
              maxLength: '1000',
            }}
          />
        </Col>
      </Row>
      <Row className='pt-2'>
        <Col xs={12} md={6}>
          {showSuccess && <p className='text-success'>Správa bola odoslaná</p>}
          <p>* Položky takto označené sú povinné.</p>
          <p>
            Odoslaním súhlasíte so{' '}
            <Link to='/gdpr'>spracovaním Vašich osobných údajov</Link>.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <p className='text-muted'>
            Táto stránka je chránená reCAPTCHA a Google&nbsp;
            <a
              className='text-muted'
              href='https://policies.google.com/privacy'
              target='_blank'
              rel='noreferrer'
            >
              Zásady ochrany osobných údajov
            </a>
            &nbsp;a Platia&nbsp;
            <a
              className='text-muted'
              href='https://policies.google.com/terms'
              target='_blank'
              rel='noreferrer'
            >
              Zmluvné podmienky
            </a>
            .
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            type='submit'
            variant='contained'
            disabled={pending}
            className='notLastButton'
          >
            Odoslať
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default ContactForm;
