// React Boostrap Examples - https://react-bootstrap.github.io/
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';
import React from 'react';
import ScrollToSection from '../ScrollToSection';

export default function Career(): JSX.Element {
  React.useEffect(() => {
    ScrollToSection();
  }, []);

  return (
    <Container className='mb-50'>
      <Row>
        <Col>
          <Heading isMain={true} text='KARIÉRA' id='new' />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h2>
            VUZ Slovakia, s.r.o. príjme – HODNOTITEĽ INTEROPERABILITY pre
            Notifikovanú osobu
          </h2>
          <br />
          <h3>Kto sme:</h3>
          <p>
            VUZ Slovakia, s.r.o. je 100% dcérskou spoločnosťou Výzkumného Ústavu
            Železničního, a.s. (VUZ), ktorá ponúka vysoko špecializované služby
            a komplexné riešenia v oblasti posudzovania a certifikácie
            železničných vozidiel a všetkých štrukturálnych subsystémov
            železničnej dopravy. VUZ je spoločnosť s viac ako päťdesiat ročnými
            skúsenosťami v oblasti železničného výskumu a skúšobníctva. Skupina
            VUZ tak ponúka nielen autorizované a akreditované činnosti a
            skúšobníctvo v oblasti železničných vozidiel a ich komponentov, ako
            aj vo zvyšných štrukturálnych subsystémoch železničnej dopravy, ale
            aj ďalšie činnosti v oblasti výskumu a vývoja v doprave, prieskumu
            trhu, spracovania dát či poradenskej činnosti pri spracúvaní
            projektov na čerpanie finančných zdrojov EU. Našimi zákazníkmi sú
            výrobcovia koľajových vozidiel a komponentov pre železničné systémy
            a železničnú dopravu, vlastníci železničnej infraštruktúry, stavebné
            firmy, prevádzkovatelia dráhy a železničnej dopravy, štátne orgány a
            inštitúcie, a to nie len v tuzemsku ale aj v zahraničí.
          </p>
          <h3>Naše požiadavky:</h3>
          <ul>
            <li>
              <b>
                Kvalifikácia „Samostatný hodnotiteľ“ v rámci Notifikovanej osoby
                <br />
                (pre akýkoľvek subsystém - INF, ENE, CCS, RST);
              </b>
            </li>
            <li>
              <b>Prax v posudzovaní interoperability pre Notifikovanú osobu;</b>
            </li>
            <li>
              <b>
                Splnenie požiadavok Technického dokumentu ERA MRA1044 v rozsahu
                INŠPEKTOR
              </b>{' '}
              (rozsah AUDITOR výhodou);
            </li>
            <li>Schopnosť samostatného posudzovania a spracovania posudkov;</li>
            <li>Zručnosť projektového riadenia;</li>
            <li>Zručnosť práce na PC (min. MS Office);</li>
            <li>Komunikatívna znalosť AJ (ďalší jazyk výhodou);</li>
            <li>Vodičský preukaz – skupiny B;</li>
            <li>Samostatnosť, starostlivosť, dôslednosť.</li>
          </ul>
          <h3>Pracovná náplň:</h3>
          <ul>
            <li>
              Pôsobenie v roli hodnotiteľa pre samostatné posudzovanie
              interoperability;
            </li>
            <li>Komunikácia so zákazníkmi;</li>
            <li>
              Spracovanie podkladov a vedenie evidencie projektového riadenia
              zákaziek;
            </li>
            <li>
              Spolupráca s ostatnými odbornými útvarmi VUZ (skúšobné
              laboratórium apod.);
            </li>
            <li>
              Účasť na medzinárodných rokovaniach technických skupín v EU.
            </li>
          </ul>
          <h3>Ponúkame:</h3>
          <ul>
            <li>
              Možnosť práce v Bratislave, Žiline, na homeoffice (alebo
              kombinácia podľa dohody);
            </li>
            <li>
              Zaručenú mzdu a zaujímavé výkonnostné odmeny (základný plat 2.000
              EUR brutto + odmeny);
            </li>
            <li>
              Trvalé zázemie silnej, stabilnej a prosperujúcej skupiny VUZ;
            </li>
            <li>
              Istú perspektívu v neustále sa vyvíjajúcom technickom obore;
            </li>
            <li>5 týždňov dovolenky;</li>
            <li>4 dni sickday;</li>
            <li>
              Služobný notebook, mobilný telefón, auto (aj k súkr. úč. po
              zapracovaní a podľa výsledkov).
            </li>
          </ul>
          <p>
            Predpokladaný nástup: ihneď / podľa dohody <br />
            <br />
            Životopisy posielajte na adresu:{' '}
            <a href='mailto:fantao@vuzsk.sk?subject=Kariéra'>fantao@vuzsk.sk</a>
            <br />
            <br />
            <i>
              Pozn.: Upozorňujeme záujemcov, že v súčasnej dobe hľadáme iba
              „hotových“ hodnotiteľov s praxou. V budúcnosti otvoríme aj nábor
              na ostatné a juniorské pozície. Ďakujeme za pochopenie.
            </i>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
