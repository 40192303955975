// React Boostrap Examples - https://react-bootstrap.github.io/
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';
import InfoBox from '../Sections/InfoBox';
import Contact from '../ContactForm/ContactForm';
import InlineNews from '../InlineNews/InlineNews';

export default function Home(): JSX.Element {
  return (
    <Container className='mb-50'>
      <InfoBox />
      <InlineNews />
      <Row>
        <Col>
          <Heading
            isMain={true}
            text='CERTIFIKAČNÝ ORGÁN PRE VÝROBKY (COV VUZ SK)'
            id='certification'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>
            COV VUZ SK pre hodnotenie a auditovanie využíva nasledujúce
            certifikačné schémy:
          </h3>
          <ul>
            <li>
              Rozhodnutie Komisie 2010/713/EÚ o moduloch pre postupy
              posudzovania zhody, vhodnosti na použitie a overovania ES, ktoré
              sa majú použiť v technických špecifikáciách pre interoperabilitu
              prijatých podľa smernice Európskeho parlamentu a Rady 2008/57/ES
            </li>
            <li>
              ERA 1172/003 V1.1 Certifikačná schéma pre ECM a outsourcované
              funkcie údržby podľa nariadenia (EÚ) 2019/779
            </li>
          </ul>
          <h3>
            COV VUZ SK na požiadanie poskytuje žiadateľom a klientom informácie
            o:
          </h3>
          <ul>
            <li>postupoch hodnotenia a auditovania</li>
            <li>
              pravidlách a postupoch pre udeľovanie, udržiavanie, rozširovanie a
              obmedzovanie rozsahu certifikácie, pozastavovania, odnímania alebo
              zamietnutia certifikácie
            </li>
            <li>právach a povinnostiach žiadateľov a klientov</li>
            <li>postupoch na vybavovanie sťažností a odvolaní (reklamácií)</li>
          </ul>
          <p>
            Ceny všetkých úkonov COV VUZ SK sú zmluvné / stanovené dohodou a
            závislé od rozsahu a náročnosti certifikácie. Na vyžiadanie Vám radi
            pripravíme cenovú kalkuláciu (využite prosím kontakty a kontaktný
            formulár uvedený nižšie).
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Heading isMain={true} text='KONTAKTY' id='contact' />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className='text-center'>
          <h3>VUZ Slovakia, s. r. o.</h3>
          <p>
            Seberíniho 1
            <br />
            821 03 Bratislava – Ružinov
            <br />
            Slovenská republika
            <br />
            IČO: 53156587
          </p>
        </Col>
        <Col sm={6} className='text-center'>
          <iframe
            src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJfZUWpyyPbEcRiPV6Ahefj54&key=AIzaSyAwC1xDSXVUQ3gHJ2NMDoOdSLkdgSnSOFM'
            width='auto'
            height='268'
            frameBorder='0'
            title='Mapa'
            style={{ border: 0 }}
            aria-hidden='false'
            className='float-lg-center w-100 iframe-map'
          ></iframe>
        </Col>
      </Row>
      <Row>
        <Col>
          <Heading isMain={false} text='VEDENIE SPOLOČNOSTI' />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className='text-center profile'>
          <h4>Ing. Ondřej Fanta, Ph.D.</h4>
          <ul>
            <li>konateľ</li>
            <li>
              <a href='mailto:fantao@vuzsk.sk?subject=Dotaz'>fantao@vuzsk.sk</a>
            </li>
          </ul>
        </Col>
        <Col sm={6} className='text-center profile'>
          <h4>Ing. Peter Pribylinec</h4>
          <ul>
            <li>konateľ a výkonný riaditeľ</li>
            <li>
              <a href='mailto:pribylinecp@vuzsk.sk?subject=Dotaz'>
                pribylinecp@vuzsk.sk
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Heading isMain={false} text='KONTAKTNÝ FORMULÁR' id='message' />
        </Col>
      </Row>
      <Contact />
    </Container>
  );
}
