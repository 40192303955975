// React Boostrap Examples - https://react-bootstrap.github.io/
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';

export default function Gdpr(): JSX.Element {
  return (
    <Container className='mb-50'>
      <Row>
        <Col>
          <Heading isMain={true} text='GDPR' id='contact' />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h2>
            PRIVACY POLICY
            <br />
            (zásady ochrany osobných údajov)
          </h2>
          <p>
            <ol>
              <li>
                ÚVOD
                <br />
                Naša spoločnosť, Výzkumný Ústav Železniční, a.s., IČO: 27257258,
                so sídlom Praha 4 - Braník, Novodvorská 1698/138b, PSČ 142 00,
                zapísaná v obchodnom registri vedenom Mestský sudom v Prahe,
                oddiel B, vložka 10025, v rámci svojej činnosti používa osobné
                údaje svojich zákazníkov a klientov, obchodných partnerov, ich
                zástupcov a zamestnancov, návštevníkov priestorov, v ktorých
                pôsobíme, ako aj osobné údaje našich zamestnancov alebo
                uchádzačov o zamestnanie a členov našich orgánov.
                <br />
                <br />
                Ochranu vašich osobných údajov a vášho súkromia neberieme na
                ľahkú váhu a robíme všetko pre to, aby sme ich primerane
                zabezpečili. S osobnými údajmi nakladáme v plnom súlade s
                platnými právnymi predpismi, t. j. nariadením Európskeho
                parlamentu a Rady (EÚ) č. 2016/679 z 27. apríla 2016 o ochrane
                fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe
                takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej len
                „GDPR“), a súvisiacimi vnútroštátnymi právnymi predpismi.
                <br />
                <br /> V tomto dokumente vysvetľujeme, aké osobné údaje
                zhromažďujeme, na aký účel, ako ich používame, čo robíme pre ich
                bezpečnosť a aké práva môžete voči nám uplatniť.
              </li>
              <br />
              <br />
              <li>
                PREVÁDZKOVATEĽ A KONTAKTNÉ ÚDAJE
                <br />
                Naša spoločnosť je v zmysle čl. 4 ods. 7 nariadenia GDPR
                prevádzkovateľom osobných údajov. So svojimi otázkami,
                žiadosťami alebo námietkami týkajúcimi sa spracúvania alebo
                ochrany osobných údajov sa môžete na našu spoločnosť obrátiť
                poštou, e-mailom alebo telefonicky:
                <br />
                Mgr. Nikola Kortánová | podnikový právnik
                <br /> Výzkumný ústav Železniční, a.s.
                <br />{' '}
                <a
                  href='https://goo.gl/maps/5peqoftYXpmZuu2ZA'
                  target='_blank'
                  rel='noreferrer'
                >
                  Novodvorská 1698/138b, 142 01 Praha 4-Braník
                </a>
                <br />
                E-mail:{' '}
                <a href='mailto:gdpr@cdvuz.cz?subject=Dotaz'>gdpr@cdvuz.cz</a>
                <br /> Tel.: <a href='tel:+420725873150'>+420 725 873 150</a>
              </li>
              <br />
              <br />
              <li>
                DEFINÍCIE ZÁKLADNÝCH POJMOV <br />
                Osobný údaj/osobné údaje – akékoľvek informácie, ktoré samy o
                sebe alebo v kombinácii s inými informáciami môžu viesť k
                identifikácii konkrétnej osoby (tzv. dotknutej osoby).
                <br />
                <br />
                <ul>
                  <li>
                    všeobecné – meno, priezvisko, pohlavie, vek, osobný stav,
                    občianstvo, národnosť, adresa, e-mailová adresa, telefónne
                    číslo, fotografia, záznam z priemyselných kamier, údaje o
                    osobnom vzhľade, cookie, používateľské meno, IP adresa,
                    pracovná pozícia, mzda atď.;
                  </li>
                  <br />
                  <li>
                    osobitné kategórie údajov – rasový alebo etnický pôvod,
                    politické názory, náboženské a filozofické presvedčenie,
                    členstvo v odboroch, zdravotný stav, sexuálna orientácia,
                    trestné činy a právoplatné odsúdenia, údaje o deťoch,
                    genetické a biometrické údaje.
                  </li>
                </ul>
                <br />
                <br />
                Prevádzkovateľ – osoba (subjekt), ktorá určuje účel a spôsob
                spracúvania osobných údajov, t. j. naša spoločnosť.
                <br />
                <br />
                Sprostredkovateľ - osoba (subjekt), ktorá spracúva osobné údaje
                v mene prevádzkovateľa, t. j. naša spoločnosť alebo iný subjekt
                (právnická alebo fyzická osoba) poverený našou spoločnosťou.
                <br />
                <br />
                Výzkumný Ústav Železniční,
                <br /> a.s. Novodvorská 1698/138b, 142 01 Praha 4-Braník | IČO
                27257258 | DIČ CZ27257258
                <br /> T +420 241 493 135 | info@cdvuz.cz
                <br /> www.cdvuz.cz
                <br />
                <br /> Dotknutá osoba - fyzická osoba, ktorej sa osobné údaje
                týkajú (napr. zamestnanec našej spoločnosti, uchádzač o
                zamestnanie, zástupca alebo zamestnanec obchodného partnera
                našej spoločnosti, návštevník priestorov našej spoločnosti
                atď.).
                <br />
                <br />
                Spracúvanie osobných údajov - akákoľvek operácia alebo súbor
                operácií s osobnými údajmi alebo súbormi osobných údajov
                automatizovanými alebo neautomatizovanými prostriedkami
                (zhromažďovanie, zaznamenávanie, usporadúvanie, štruktúrovanie,
                uchovávanie, prispôsobovanie, zmena, vyhľadávanie, prehliadanie,
                poskytovanie prenosom, používanie, šírenie, akékoľvek iné
                zverejňovanie, zosúlaďovanie, kombinovanie, obmedzovanie,
                vymazávanie, likvidácia).
              </li>
              <br />
              <br />
              <li>
                AKÉ OSOBNÉ ÚDAJE SPRACÚVAME?
                <br />
                <b>Bez vášho súhlasu</b>
                <br />
                <ul>
                  <li>
                    Identifikačné údaje – osobné údaje, ktoré slúžia na vašu
                    jednoznačnú a nezameniteľnú identifikáciu, napr. meno,
                    priezvisko, titul, dátum narodenia, občiansky preukaz,
                    adresa bydliska, podpis, prihlasovacie údaje do aplikácií
                    našej spoločnosti atď.
                  </li>
                  <br />
                  <li>
                    Kontaktné údaje – údaje, ktoré nám umožňujú kontaktovať vás,
                    napr. telefónne číslo, e-mailová adresa, kontaktná
                    (doručovacia) adresa atď.
                  </li>
                  <br />
                  <li>
                    Ďalšie osobné údaje - údaje o nákupoch a našej obchodnej
                    spolupráci, napr. história transakcií, platobné údaje,
                    bankový účet, platobná karta atď.
                  </li>
                  <br />
                  <li>
                    Údaje o vstupoch do priestorov našej spoločnosti - meno a
                    priezvisko vstupujúcej osoby.
                  </li>
                  <br />
                  <li>
                    Fotografie – napr. fotografie zamestnancov našej
                    spoločnosti, atď.
                  </li>
                  <br />
                  <li>
                    Záznamy z priemyselných kamier - v prípade pohybu v
                    snímaných priestoroch našej spoločnosti.
                  </li>
                  <br />
                  <li>
                    Údaje zhromaždené o zariadeniach v súvislosti s používaním
                    aplikácií našej spoločnosti - keď používate naše webové
                    stránky alebo aplikácie, používame rôzne technológie na
                    zhromažďovanie a ukladanie informácií na identifikáciu vášho
                    prehliadača a zariadenia (súbory cookie a podobné
                    technológie), zhromažďovanie údajov o zariadení (napr. IP
                    adresa alebo iné jedinečné identifikátory vášho zariadenia,
                    model hardvéru, verzia operačného systému, údaje o mobilnej
                    sieti, protokoly serverov, adresa internetového protokolu,
                    dátum a čas vašej požiadavky), údaje o polohe (IP adresa,
                    systém GPS, prístupové body siete WI-FI alebo vysielač
                    mobilnej siete.
                  </li>
                  <br />
                  <li>
                    Údaje o uplatňovaní vašich zákonných práv a záznamy o ich
                    uplatňovaní voči našej spoločnosti.
                  </li>
                  <br />
                  <li>
                    Iné údaje, ktorých spracúvanie našej spoločnosti ukladajú
                    české právne predpisy alebo právne predpisy EÚ s vaším
                    súhlasom. V niektorých prípadoch môže naša spoločnosť
                    spracúvať vyššie uvedené osobné údaje alebo iné osobné údaje
                    na základe vášho výslovného súhlasu. V takýchto prípadoch
                    musí byť presný rozsah spracúvaných osobných údajov uvedený
                    v súhlase, ktorý ste podpísali. V takýchto prípadoch môžete
                    svoj súhlas so spracúvaním osobných údajov kedykoľvek
                    odvolať.
                  </li>
                </ul>
              </li>
              <br />
              <br />
              <li>
                NA AKÉ ÚČELY NAŠA SPOLOČNOSŤ POUŽÍVA OSOBNÉ ÚDAJE?
                <br />V súlade s platnými právnymi predpismi zhromažďujeme a
                spracúvame osobné údaje na vopred stanovený účel a len v rozsahu
                potrebnom na splnenie tohto účelu.
                <br />
                <b>Bez vášho súhlasu</b>
                <br />
                <ul>
                  <li>
                    Na účely plnenia zmluvy, ktorú ste s nami uzavreli. V tejto
                    zmluve je uvedené, aké údaje musíme spracúvať, aby sme
                    splnili všetko, čo sme si dohodli, a čo nám v súvislosti s
                    takouto zmluvou ukladá zákon (napr. zákon o účtovníctve,
                    daňový poriadok, zákonník práce atď.). Tento účel a zákonný
                    základ spracúvania sa vzťahuje aj na prípravu zmluvy,
                    rokovanie o podmienkach zmluvy alebo uskutočnenie výberového
                    konania. <br />
                    www.cdvuz.cz
                  </li>
                  <br />
                  <li>
                    Na účely plnenia zákonných povinností (napr. zákon o
                    účtovníctve, daňový poriadok, zákonník práce, zákon o DPH,
                    zákon o spisovej a archívnej službe atď.).
                  </li>
                  <br />
                  <li>
                    Na účely riadenia vzťahov so zákazníkmi a vytvárania
                    analytických modelov - aby sme vám mohli pohodlne a rýchlo
                    poskytovať služby podľa vašich potrieb, spracúvame tiež
                    údaje o nákupoch, požiadavkách alebo sťažnostiach,
                    porovnávame a analyzujeme údaje o produktoch našej
                    spoločnosti, vytvárame štatistiky a prognózy odbytu, aby sme
                    chránili naše práva a oprávnené záujmy. V týchto prípadoch
                    sa snažíme údaje čo najviac anonymizovať.
                  </li>
                  <br />
                  <li>
                    Na účely bezpečnosti a riadenia rizík - ak to od nás
                    vyžaduje zákon alebo ochrana našich oprávnených záujmov,
                    spracúvame vaše osobné údaje v rozsahu potrebnom na
                    zaistenie bezpečnosti v našich priestoroch, na ochranu nášho
                    majetku, na prevenciu a odhaľovanie podvodného alebo
                    škodlivého konania atď.
                  </li>
                  <br />
                  <li>
                    Na účely uplatnenia alebo obhajoby našich právnych nárokov -
                    ak budeme musieť uplatniť naše práva alebo ich obhajovať v
                    súdnom alebo správnom konaní, použijeme potrebné osobné
                    údaje.
                  </li>
                  <br />
                  <li>
                    Na účely vnútornej správy našej spoločnosti - naši
                    zamestnanci spracúvajú vaše osobné údaje aj pri plnení
                    svojich pracovnoprávnych povinností v rámci interných
                    procesov našej spoločnosti. Ide napríklad o internú správu
                    našej spoločnosti, vytváranie správ o činnosti našej
                    spoločnosti alebo jednotlivých zamestnancov, snahu o
                    optimalizáciu interných procesov alebo potrebu školenia
                    zamestnancov. <br />
                  </li>
                </ul>
                <br />
                <b>S vaším súhlasom</b>
                <br />
                S vaším súhlasom môžeme spracúvať osobné údaje aj na iné účely
                (napr. marketingové atď.). V takýchto prípadoch je účel
                zhromažďovania a ďalšieho nakladania s osobnými údajmi presne
                vymedzený vo vašom súhlase. V takýchto prípadoch máte vždy
                možnosť tento súhlas kedykoľvek odvolať.
                <br />
                <br />
                Použitie osobných údajov na iný účel, než na ktorý boli
                zhromaždené.
                <br /> V niektorých prípadoch je naša spoločnosť oprávnená
                spracúvať osobné údaje na iný účel, než na aký boli zhromaždené.
                <br /> Ide najmä o prípady, keď vaše údaje zhromažďujeme na
                účely plnenia zmluvy alebo poskytnutia služby a právny predpis
                nám následne ukladá, ako dlho musíme údaje uchovávať (napr.
                podľa zákona o účtovníctve musíme uchovávať faktúry obsahujúce
                cenu tovaru alebo poskytnutých služieb 10 rokov, hoci už údaje
                nepotrebujeme na účely plnenia zmluvy). Alebo naša spoločnosť
                zhromažďuje osobné údaje o svojich zamestnancoch na účely
                plnenia zákonných povinností a následne z dôvodu nehody alebo
                inej mimoriadnej udalosti, vrátane zneužitia alebo poškodenia
                majetku našej spoločnosti, spracúvame zhromaždené osobné údaje
                (najmä identifikačné údaje zamestnanca, ktorý sa na mimoriadnej
                udalosti zúčastnil alebo ju priamo spôsobil) na účely vyšetrenia
                týchto udalostí alebo v prípade sporu na účely vymáhania našich
                právnych nárokov alebo obhajoby našich práv.
              </li>
              <br />
              <br />
              <li>
                Z AKÝCH ZDROJOV NAŠA SPOLOČNOSŤ ZÍSKAVA OSOBNÉ ÚDAJE?
                <br />
                <ul>
                  <li>
                    Priamo od vás ako dotknutej osoby alebo ako osoby povinnej
                    poskytnúť údaje na splnenie zákonnej povinnosti, pri
                    rokovaní o zmluve alebo inej dohode alebo pri vypĺňaní
                    vstupného dotazníka.
                  </li>
                  <br />
                  <li>
                    Z kamerového systému našej spoločnosti a systému na
                    zaznamenávanie vstupov do našich priestorov a výstupov z
                    našich priestorov.
                  </li>
                  <br />
                  <li>
                    Od poskytovateľov telekomunikačných služieb alebo iných
                    služieb, ktoré naša spoločnosť využíva v rámci svojej
                    podnikateľskej činnosti alebo pri plnení povinností našej
                    spoločnosti (napr. rozúčtovanie hovorov na firemných
                    telefónoch, používanie firemných vozidiel, monitorovanie
                    činnosti na firemných počítačoch atď.).
                  </li>
                  <br />
                  <li>
                    Od iných subjektov, ak to výslovne povoľuje alebo vyžaduje
                    zákon (napr. údaje zaslané orgánmi verejnej moci do dátovej
                    schránky našej spoločnosti alebo zverejnené v rámci súdneho
                    sporu, údaje od poisťovne našej spoločnosti alebo poskytnuté
                    odborovou organizáciou na základe zákonných podmienok),
                    alebo ak ste tomuto inému subjektu udelili súhlas na prenos
                    informácií o vás.
                  </li>
                  <br />
                  <li>
                    Na interné administratívne účely môžeme prenášať údaje medzi
                    sebou a inými subjektmi v rámci skupiny ČD.
                  </li>
                  <br />
                  <li>
                    Z otvorených alebo verejne dostupných zdrojov - ako sú
                    verejne dostupné registre a záznamy, webové stránky alebo
                    inzeráty - ak je to potrebné na ochranu práv a oprávnených
                    záujmov našej spoločnosti alebo tretej strany, napr. na
                    overenie aktuálnosti údajov, na komunikačné účely atď. V
                    rámci koncernu/skupiny ČD prenášame určité osobné údaje
                    našich obchodných partnerov, zákazníkov a/alebo
                    zamestnancov, a to predovšetkým na účely internej správy a
                    výkazníctva. Účelom však môže byť aj uľahčenie uzatvárania
                    zmlúv, poskytovania služieb alebo riešenia určitých
                    záležitostí. Zoznam členov skupiny ČD nájdete v bode 10.
                  </li>
                </ul>
                <br />
                <br /> Výzkumný Ústav Železniční, a.s.
                <br />
                Novodvorská 1698/138b, 142 01 Praha 4-Braník | IČO 27257258 |
                DIČ CZ27257258
                <br />T +420 241 493 135 | info@cdvuz.cz
                <br /> www.cdvuz.cz
              </li>
              <br />
              <br />
              <li>
                STE POVINNÍ POSKYTNÚŤ NÁM OSOBNÉ ÚDAJE?
                <br />
                Ak spracúvame vaše osobné údaje na základe vášho súhlasu, prenos
                je úplne dobrovoľný.
                <br />
                Ak spracúvame osobné údaje v súvislosti so zmluvou alebo
                dohodou, ktorú ste s nami uzavreli, alebo so službou, ktorú vám
                poskytujeme, môžete sa dobrovoľne rozhodnúť, či zmluvu uzavriete
                alebo službu využijete.
                <br />
                <br />
                Ak uzavriete zmluvu alebo využijete službu, ste povinní
                poskytnúť nám informácie potrebné na plnenie zmluvy alebo
                využívanie služby. Bez týchto informácií vám nemôžeme poskytnúť
                službu ani iné plnenie.
                <br />
                <br />
                Ak je dôvodom zhromažďovania a ďalšieho spracúvania vašich
                osobných údajov plnenie zákonných povinností alebo ochrana
                našich oprávnených záujmov, potom ste povinní poskytnúť nám
                svoje osobné údaje. Vždy však žiadame len údaje potrebné na
                splnenie stanoveného účelu.
              </li>
              <br />
              <br />
              <li>
                AKÝM SPÔSOBOM ZABEZPEČUJEME OCHRANU VAŠICH ÚDAJOV?
                <br />V súlade s platnými právnymi predpismi naša spoločnosť
                zabezpečuje osobné údaje, ktoré spracúva, pomocou všetkých
                vhodných technických, organizačných a bezpečnostných opatrení,
                aby sa zabezpečila najvyššia možná úroveň ochrany s
                prihliadnutím na povahu, rozsah a účely spracúvania a
                pravdepodobné riziká. Máme zavedené bezpečnostné a kontrolné
                mechanizmy, aby sme zabránili neoprávnenému prístupu k údajom
                alebo ich prenosu, ich strate, zničeniu alebo inému možnému
                zneužitiu.
                <br />
                <br />
                Zamestnanci alebo oprávnení zástupcovia našej spoločnosti, ktorí
                majú prístup k vašim osobným údajom, sú viazaní povinnosťou
                mlčanlivosti. Prístup k vašim osobným údajom majú len tí
                zamestnanci, ktorí vykonávajú činnosti potrebné na zabezpečenie
                vyššie uvedených účelov, a tí majú vždy prístup len k údajom
                potrebným na výkon svojej práce.
                <br />
                <br />
                Ak údaje odovzdávame tretím stranám, aj tieto strany sú viazané
                zákonnou alebo zmluvnou povinnosťou mlčanlivosti. Zároveň sa
                snažíme vyberať si len takých partnerov, ktorí sú dostatočne
                dôveryhodní a zabezpečujú prenášané osobné údaje.
                <br />
                <br />
                Naša spoločnosť spracúva osobné údaje manuálne aj automaticky,
                najmä v našich zabezpečených PC systémoch a v PC systémoch
                sprostredkovateľov (napr. v našom systéme, v programe Outlook
                pre e-maily, v našom účtovnom systéme pre fakturačné údaje
                atď.). Naša spoločnosť uchováva dokumenty vo svojej kartotéke,
                ktorá je podľa potreby zabezpečená.
              </li>
              <br />
              <br />
              <li>
                KOMU PRENÁŠAME VAŠE OSOBNÉ ÚDAJE?
                <br />
                <ul>
                  <li>
                    {' '}
                    Sprostredkovateľom - naša spoločnosť vykonáva väčšinu
                    činností spracúvania vlastnými silami, v niektorých
                    prípadoch však využívame tretie strany (ďalej len
                    „sprostredkovatelia“). Pri výbere týchto špecializovaných
                    externých dodávateľov naša spoločnosť po dôkladnom zvážení
                    vyberá takú osobu, ktorá poskytuje maximálne záruky
                    bezpečnosti prenášaných osobných údajov. V rámci zmlúv o
                    spracúvaní sa snažíme zaviazať sprostredkovateľa, aby
                    poskytoval minimálne rovnakú úroveň ochrany prenášaných
                    osobných údajov, akú poskytuje naša spoločnosť, a zároveň ho
                    zaväzujeme k povinnosti mlčanlivosti. Sprostredkovateľ je
                    okrem toho oprávnený spracúvať prenesené údaje výlučne na
                    účely vykonávania činnosti, ktorou ho naša spoločnosť
                    poverila. Aj pri používaní cloudového úložiska je
                    zabezpečená úroveň ochrany požadovaná platnými právnymi
                    predpismi.
                    <br />
                    <br />
                    Výzkumný Ústav Železniční, a.s.
                    <br />
                    Novodvorská 1698/138b, 142 01 Praha 4-Braník | IČO 27257258
                    | DIČ CZ27257258 <br />T +420 241 493 135 | info@cdvuz.cz{' '}
                    <br />
                    www.cdvuz.cz
                    <br />
                    <br />
                    Sprostredkovatelia sú napr.:{' '}
                    <ul>
                      <li>
                        dodávatelia softvérových služieb a aplikácií (personálne
                        a účtovné systémy atď.),
                      </li>
                      <li>
                        poskytovatelia IT služieb, aplikácií a cloudových
                        úložísk,
                      </li>
                      <li>poskytovatelia účtovných služieb, </li>
                      <li>poskytovatelia archivačných služieb, </li>
                      <li>
                        právni zástupcovia alebo subjekty vymáhajúce naše
                        pohľadávky,
                      </li>
                      <li>marketingové agentúry,</li>
                      <li>finanční a daňoví poradcovia, audítori.</li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    {' '}
                    Našim obchodným partnerom - ak poveríme niekoho iného
                    vykonávaním činnosti, ktorá je súčasťou našich služieb, môže
                    byť potrebný prenos osobných údajov. Tieto subjekty sa samy
                    stávajú prevádzkovateľom vašich osobných údajov (najmä
                    dopravca alebo Česká pošta).
                  </li>
                  <br />
                  <li>
                    {' '}
                    Našim poradcom - ak je to potrebné na účely poskytovania
                    poradenských služieb našej spoločnosti alebo ak je to
                    potrebné na ochranu práv a záujmov našej spoločnosti (napr.
                    právnemu zástupcovi, daňovému alebo ekonomickému poradcovi,
                    poradcovi v oblasti bezpečnosti a zabezpečenia siete,
                    audítorom, poisťovniam alebo poisťovacím maklérom, bankám,
                    súdom, exekútorom, dražobníkom atď.).
                  </li>
                  <br />
                  <li>
                    {' '}
                    Spoločnostiam v rámci koncernu/skupiny ČD - v rámci
                    koncernu/skupiny ČD odovzdávame niektoré osobné údaje našich
                    obchodných partnerov, zákazníkov alebo zamestnancov a členov
                    našich orgánov, a to najmä na účely internej správy a
                    výkazníctva. Účelom však môže byť aj uľahčenie uzatvárania
                    zmlúv, poskytovania služieb alebo riešenia určitých
                    záležitostí.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Štátnym orgánom alebo iným subjektom v prípadoch, keď to
                    našej spoločnosti ukladá zákon (napr. orgánom štátnej
                    správy, orgánom dohľadu, orgánom činným v trestnom konaní,
                    súdom, exekútorom, notárom, správcom konkurznej podstaty).
                  </li>
                  <br />
                  <li>
                    {' '}
                    Vaše osobné údaje môžu byť s vaším súhlasom/pokynom
                    poskytnuté aj iným subjektom.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Naša spoločnosť je oprávnená prenášať vaše osobné údaje v
                    rámci EÚ v súlade so zásadou voľného pohybu osôb bez
                    obmedzenia. Naša spoločnosť je oprávnená prenášať osobné
                    údaje aj mimo EÚ, napr. ak sprostredkovateľ spracúva osobné
                    údaje v počítačovom programe, ktorého servery sa nachádzajú
                    alebo sú umiestnené v krajinách mimo EÚ, alebo ak príslušnú
                    službu alebo produkt poskytuje v požadovanej kvalite len
                    obchodný partner z krajiny mimo EÚ. Vo všetkých takýchto
                    prípadoch naša spoločnosť vyžaduje od svojich obchodných
                    partnerov, aby poskytovali minimálne rovnakú úroveň ochrany
                    prenášaných osobných údajov, akú poskytuje ona sama, v
                    súlade s nariadením GDPR. Naša spoločnosť bude súhlasiť s
                    prenosom osobných údajov len vtedy, ak budú poskytnuté
                    primerané záruky a za podmienky, že budú k dispozícii
                    vymožiteľné práva vás ako dotknutej osoby a vaša účinná
                    ochrana.
                  </li>
                </ul>
              </li>
              <br />
              <br />
              <li>
                KONCERN/SKUPINA ČD
                <br />
                Do koncernu/skupiny ČD patria tieto spoločnosti:
                <br />
                <ul>
                  <li> ČD, a.s.</li>
                  <br />
                  <li> ČD Cargo, a.s.</li>
                  <br />
                  <li> Výzkumný Ústav Železniční, a.s.</li>
                  <br />
                  <li> DPOV, a.s.</li>
                  <br />
                  <li> ČD – Informační Systémy, a.s.</li>
                  <br />
                  <li> Dopravní vzdělávací institut, a.s.</li>
                  <br />
                  <li> ČD Reality, a.s.</li>
                  <br />
                  <li> RailReal a.s.</li>
                  <br />
                  <li> ČD – Telematika a.s.</li>
                  <br />
                  <li> ČD travel, s.r.o.</li>
                  <br />
                  <li> Smíchov Station Development, a.s.</li>
                  <br />
                  <li> Žižkov Station Development, a.s.</li>
                  <br />
                  <li> ČD Restaurant, a.s.</li>
                  <br />
                  <li> JLV, a.s.</li>
                  <br />
                  <li> Masaryk Station Development, a.s.</li>
                  <br />
                  <li> CR-City a.s.</li>
                  <br />
                  <li> RS residence s.r.o.</li>
                  <br />
                  <li> Hit Rail, B.V.</li>
                  <br />
                  <li> BCC, s.c.r.l.</li>
                  <br />
                  <li>
                    {' '}
                    EUROFIMA Výzkumný Ústav Železniční, a.s. Novodvorská
                    1698/138b, 142 01 Praha 4-Braník | IČO 27257258 | DIČ
                    CZ27257258 T +420 241 493 135 | info@cdvuz.cz www.cdvuz.cz
                  </li>
                </ul>
              </li>
              <br />
              <br />
              <li>
                OBDOBIE UCHOVÁVANIA VAŠICH OSOBNÝCH ÚDAJOV
                <br />
                Vaše osobné údaje spracúvame tak dlho, ako je to potrebné na
                splnenie účelu, na ktorý boli zhromaždené, alebo na iný
                súvisiaci účel. Veľkú časť vašich osobných údajov budeme
                spracúvať počas trvania zmluvného vzťahu, pretože účel
                spracúvania trvá počas celého tohto obdobia.
                <br />
                <br /> Vo väčšine prípadov zákon priamo stanovuje, ako dlho sa
                musia určité osobné údaje spracúvať - napr. účtovné údaje 10
                rokov atď.
                <br />
                <br /> Ak nie je priamo zákonom stanovené konkrétne obdobie,
                počas ktorého sa musia osobné údaje spracúvať, predpokladáme, že
                účel spracúvania trvá, a preto budeme osobné údaje spracúvať po
                dobu, počas ktorej hrozia právne nároky voči našej spoločnosti
                (zvyčajne 10-ročná premlčacia doba) a 1 kalendárny rok po
                ukončení všetkých hroziacich právnych nárokov (zvyčajne 11 rokov
                po ukončení zmluvného vzťahu).
                <br />
                <br />
                Kamerové záznamy sa uchovávajú 14 dní odo dňa ich získania, ak
                po vyhotovení záznamu nevznikne potreba dlhšieho uchovávania
                alebo ochrany (napr. v prípade, že kamerový záznam zachytáva
                škodlivý čin, v prípade sporu a následnej potreby uplatniť naše
                právne nároky alebo brániť naše práva). Záznamy z knihy návštev
                budeme uchovávať 14 dní odo dňa ich vyhotovenia, ak po ich
                vyhotovení nevznikne potreba dlhšieho uchovávania alebo ochrany
                (napr. v prípade havárie alebo inej podobnej mimoriadnej
                udalosti). Pre zamestnancov a členov štatutárneho orgánu našej
                spoločnosti máme zavedený dochádzkový systém s čipom, pričom
                primárne záznamy z tohto systému uchovávame 12 mesiacov, ak po
                vyhotovení záznamov nevznikne potreba ich dlhšieho uchovávania
                alebo ochrany (napr. v prípade sporu a potreby vymáhania
                právnych nárokov našej spoločnosti alebo ochrany našich práv).
                <br />
                <br /> Ak spracúvame osobné údaje na základe vášho súhlasu,
                budeme ich spracúvať počas obdobia uvedeného v súhlase alebo do
                odvolania súhlasu.{' '}
              </li>
              <br />
              <br />
              <li>
                AKÉ SÚ VAŠE PRÁVA A MOŽNOSTI?
                <br />
                <ul>
                  <li>
                    {' '}
                    Právo na informácie a vysvetlenie
                    <br /> Naša spoločnosť je povinná poskytnúť vám informácie
                    uvedené v tomto dokumente stručne, transparentne a
                    zrozumiteľne. Ak vám niektoré ustanovenie tohto dokumentu
                    nie je jasné alebo úplne zrozumiteľné, neváhajte nás
                    kontaktovať.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo odvolať súhlas
                    <br /> Ak údaje zhromažďujeme a spracúvame na základe vášho
                    súhlasu, máte právo tento súhlas kedykoľvek odvolať.
                    Poskytnutie súhlasu je úplne dobrovoľné. Ak svoj súhlas
                    odvoláte, nemá to vplyv na tie spracovateľské činnosti,
                    ktoré sa už uskutočnili v čase, keď bol súhlas platne
                    udelený, ani na tie spracovateľské činnosti, ktoré je naša
                    spoločnosť povinná vykonať z dôvodu predtým udeleného
                    súhlasu a už vykonaných spracovateľských činností (s cieľom
                    splniť právne povinnosti alebo chrániť naše oprávnené
                    záujmy). <br />
                    <br />
                    Odvolanie súhlasu je bezplatné a súhlas môžete odvolať
                    kontaktovaním určeného zamestnanca:
                    <br />
                    <br /> Mgr. Nikola Kortánová | podnikový právnik <br />
                    Výzkumný Ústav Železniční, a.s. Novodvorská 1698/138b, 142
                    01 Praha 4-Braník <br />
                    E-mail: gdpr@cdvuz.cz
                    <br /> Tel.: +420 725 873 150 <br />
                    <br />
                    Osobné údaje budeme uchovávať 1 rok po uplynutí premlčacej
                    alebo prekluzívnej lehoty, aby sme sa uistili, že ani v
                    posledný deň lehoty nebola proti našej spoločnosti podaná
                    žaloba alebo sťažnosť na súd alebo iný orgán.
                    <br />
                    <br /> Výzkumný Ústav Železniční, a.s.
                    <br /> Novodvorská 1698/138b, 142 01 Praha 4-Braník | IČO
                    27257258 | DIČ CZ27257258
                    <br /> T +420 241 493 135 | info@cdvuz.cz
                    <br /> www.cdvuz.cz
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo na prístup k osobným údajom
                    <br /> Máte právo získať od našej spoločnosti potvrdenie o
                    tom, či naša spoločnosť spracúva vaše osobné údaje. V
                    kladnom prípade vám poskytneme všetky súvisiace informácie v
                    rozsahu požadovanom čl. 15 nariadenia GDPR. Aby sme
                    zabezpečili, že toto právo nebude zneužité inou osobou, a
                    aby sme zabránili odovzdaniu vašich osobných údajov cudzej
                    osobe, sme povinní overiť si totožnosť osoby uplatňujúcej
                    právo na prístup spôsobom opísaným nižšie v časti 13 tohto
                    dokumentu.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo vzniesť námietku
                    <br /> Ak spracúvame osobné údaje na účely oprávnených
                    záujmov našej spoločnosti alebo tretej strany, máte právo
                    namietať proti takémuto spracúvaniu v prípadoch, keď to
                    odôvodňuje vaša konkrétna situácia - t. j. ak je samotné
                    spracúvanie prípustné, ale na vašej strane existujú
                    konkrétne dôvody, prečo si aj napriek tomu neželáte, aby sa
                    spracúvanie uskutočnilo. <br />
                    <br />
                    Naša spoločnosť bude musieť preskúmať vykonávané
                    spracúvanie. Takéto osobné údaje nebudeme ďalej spracúvať,
                    ak neexistujú závažné oprávnené dôvody na spracúvanie, ktoré
                    prevažujú nad vašimi záujmami na ochranu súkromia alebo
                    inými záujmami, právami a slobodami, alebo ak spracúvanie
                    nie je nevyhnutné na preukazovanie, uplatňovanie alebo
                    obhajovanie právnych nárokov našej spoločnosti.
                    <br />
                    <br /> Ak spracúvame osobné údaje na účely priameho
                    marketingu, môžete kedykoľvek namietať proti takémuto
                    spracúvaniu vašich osobných údajov. Toto právo môžete
                    uplatniť aj pomocou technických nástrojov (odhlásenie z
                    odberu obchodných oznámení). Naša spoločnosť potom nebude
                    spracúvať vaše osobné údaje na účely priameho marketingu,
                    ale môže ich spracúvať na iné účely.
                    <br />
                    <br /> Svoju námietku, ako aj ďalšie práva môžete uplatniť
                    tak, že sa obrátite na našu spoločnosť, ako je uvedené
                    vyššie (pozri bod 2 tohto dokumentu). Vždy, prosím, uveďte
                    konkrétnu situáciu, ktorá vás vedie k záveru, že by naša
                    spoločnosť nemala spracúvať vaše údaje. <br />
                    <br />
                    Možnosť vzniesť námietku sa však nevzťahuje na všetky
                    prípady spracúvania, nie je možné ju využiť v prípade, že
                    vaše údaje spracúvame na základe iného právneho základu, ako
                    je nevyhnutnosť na oprávnený účel - napr. preto, že je to
                    potrebné na plnenie zmluvy alebo plnenie zákonných
                    povinností.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo na opravu alebo doplnenie
                    <br /> Ak sa domnievate, že o vás spracúvame nepresné údaje,
                    máte právo nás na to upozorniť a požiadať o opravu alebo
                    doplnenie.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo na vymazanie (tzv. právo „byť zabudnutý“)
                    <br /> Máte právo požiadať nás o vymazanie vašich osobných
                    údajov, ak sú splnené podmienky článku 17 nariadenia GDPR –
                    najmä údaje už nie sú potrebné na vyššie uvedené účely,
                    súhlas bol odvolaný alebo zanikol iný právny základ
                    spracúvania, oprávnene ste vzniesli námietku proti
                    spracúvaniu, osobné údaje už nie sú potrebné na účely, na
                    ktoré boli zhromaždené, atď.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo na obmedzenie spracúvania Máte právo požiadať o
                    obmedzenie spracúvania vašich osobných údajov za podmienok
                    stanovených v článku 18 nariadenia GDPR.2 Poskytneme vám
                    kategórie osobných údajov, ktoré spracúvame, účely
                    spracúvania, kategórie príjemcov, ktorým môžu byť osobné
                    údaje poskytnuté, plánované trvanie spracúvania údajov,
                    informácie o zdroji týchto údajov, informácie o vašich
                    právach a informácie o tom, či dochádza k automatizovanému
                    rozhodovaniu.
                    <br />
                    <br />
                    Výzkumný Ústav Železniční, a.s.
                    <br /> Novodvorská 1698/138b, 142 01 Praha 4-Braník | IČO
                    27257258 | DIČ CZ27257258 T +420 241 493 135 | info@cdvuz.cz
                    <br /> www.cdvuz.cz
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo na prenositeľnosť údajov Článok 20 nariadenia GDPR vám
                    zaručuje právo získať od našej spoločnosti vaše osobné
                    údaje, ktoré ste nám poskytli, v štruktúrovanom, bežne
                    používanom a strojovo čitateľnom formáte. Tieto údaje môžeme
                    preniesť vám alebo inému prevádzkovateľovi, ak o to
                    požiadate a ak je to technicky možné. Toto právo sa môže
                    uplatniť, ak
                    <br /> (i) dôvodom spracúvania je váš súhlas alebo plnenie
                    zmluvy alebo poskytovanie služby našou spoločnosťou a
                    <br /> ii) spracúvanie vykonáva naša spoločnosť
                    automatizovanými prostriedkami.
                  </li>
                  <br />
                  <li>
                    {' '}
                    Právo podať sťažnosť dozornému orgánu Ak nesúhlasíte so
                    spôsobom, akým spracúvame vaše údaje, alebo ak nesúhlasíte s
                    prístupom našej spoločnosti, môžete sa kedykoľvek obrátiť so
                    sťažnosťou na Úrad na ochranu osobných údajov:
                    <br />
                    <br />
                    Úrad na ochranu osobných údajov
                    <br /> Praha 7, Pplk. Sochora 27, PSČ 170 00 <br />
                    Tel.: 234 665 111
                    <br /> E-mail: posta@uoou.cz
                    <br />
                    www.uoou.cz <br />
                    <br />
                  </li>
                  <li>
                    AKO RIEŠIME VAŠE NÁMIETKY A ŽIADOSTI? <br />
                    Uplatňovanie akýchkoľvek vašich práv nesmie mať vplyv na
                    práva iných osôb.
                    <br />
                    <br /> Ak sa na našu spoločnosť obrátite s námietkou alebo
                    žiadosťou o uplatnenie niektorého z vašich zákonných práv,
                    budeme vás informovať o prijatých opatreniach.
                    <br />
                    <br /> Ak neprijmeme žiadne opatrenie, budeme vás o tom
                    informovať a vysvetlíme vám dôvody nášho konania. Tieto
                    informácie vám poskytneme do 1 mesiaca od prijatia vašej
                    žiadosti.
                    <br />
                    <br /> Ak je vzhľadom na zložitosť a počet žiadostí potrebné
                    túto lehotu predĺžiť, do jedného mesiaca od prijatia
                    žiadosti vám to oznámime spolu s dôvodmi odkladu. Termín
                    predĺžime maximálne o ďalšie 2 mesiace. Urobíme všetko pre
                    to, aby sme vám čo najskôr poskytli informácie o prijatých
                    opatreniach.
                    <br />
                    <br /> Informácie o prijatých opatreniach vám poskytneme
                    rovnakým spôsobom, akým o ne požiadate. Všetky námietky a
                    žiadosti a naše odpovede sa podávajú a poskytujú bezplatne.
                    Ak sa však vaše žiadosti opakujú alebo sú zjavne
                    neprimerané, môžeme požadovať náhradu nákladov na
                    poskytnutie informácií alebo môžeme dokonca odmietnuť
                    žiadosti vyhovieť.
                    <br />
                    <br />
                    Naša spoločnosť môže vyhovieť vašej žiadosti alebo námietke
                    len vtedy, ak nemá pochybnosti o totožnosti osoby, ktorá
                    žiadosť alebo námietku podala. Musíme zabezpečiť, aby práva
                    neboli zneužité inými osobami a aby vaše osobné údaje neboli
                    nezákonne prenesené tretej strane. Z tohto dôvodu naša
                    spoločnosť overuje totožnosť žiadateľa, a to buď vyžiadaním
                    dodatočných informácií na potvrdenie totožnosti žiadateľa,
                    alebo predložením žiadosti alebo námietky s overeným
                    podpisom. Ak je žiadosť podaná ústne, sme oprávnení
                    požadovať preukázanie totožnosti žiadateľa, napríklad
                    predložením platného preukazu totožnosti. <br />
                    <br />
                  </li>
                  <li>
                    ZÁVER
                    <br />
                    Tento dokument nadobúda účinnosť 25. mája 2018 a bude sa
                    priebežne aktualizovať a dopĺňať.
                  </li>
                </ul>
              </li>
            </ol>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
