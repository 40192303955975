import { Container, Row, Col } from 'react-bootstrap';

export default function Header(): JSX.Element {
  return (
    <header>
      <Container fluid className='imgbg headerimg text-center'>
        <Row>
          <Col>
            <div className='headertxt'>
              <h1>VUZ</h1>
              <h2 className='subtitle'>
                EURÓPSKY LÍDER V ŽELEZNIČNOM
                <br />
                SKÚŠOBNÍCTVE A CERTIFIKÁCII
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
