// React Boostrap Examples - https://react-bootstrap.github.io/
import { Row, Col, Container } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';
import React from 'react';
import useFetch from '../../hooks/fetchNews';
import ScrollToSection from '../ScrollToSection';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function InlineNews(): JSX.Element {
  const { data, error, loading } = useFetch(
    'https://wr.cdvuz.cz/storiessk?_sort=published:DESC'
  );

  React.useEffect(() => {
    ScrollToSection();
  }, [data, error, loading]);

  return (
    <>
      <Row>
        <Col>
          <Heading isMain={true} text='NOVINKY' id='new' />
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            {error && (
              <p>
                <i>Nepodarilo sa spojiť so serverom.</i>
              </p>
            )}
            {loading && (
              <p>
                <i>Načítavam položky...</i>
              </p>
            )}
            {Array.isArray(data) &&
              data.slice(0, 3).map((item) => (
                <Box className='news-prev-container news-inline' key={item.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      '@media only screen and (max-width: 575px)': {
                        flexDirection: 'column',
                      },
                    }}
                  >
                    <p
                      style={{ marginBottom: '0px', fontSize: '20px' }}
                      className='news-prev-date'
                    >
                      {new Date(item.published).toLocaleDateString('cs-cz')}
                    </p>
                    <span className='news-inline-content'>
                      <Link to={`/news/${item.id}#new`}>
                        {item.lang[0].title}
                      </Link>
                    </span>
                  </Box>
                </Box>
              ))}
            <Box>
              <Link to='/news#new'>Ďalšie novinky {`>>`}</Link>
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  );
}
