// React Boostrap Examples - https://react-bootstrap.github.io/
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';
import useFetch from '../../hooks/fetchNews';
import { useParams } from 'react-router-dom';
import ScrollToSection from '../ScrollToSection';
import React from 'react';
import parseHTML from 'html-react-parser';
import { Box } from '@mui/material';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

export default function NewsDetails(): JSX.Element {
  const { id } = useParams();
  const { data, error, loading } = useFetch(
    'https://wr.cdvuz.cz/storiessk/' + id
  );

  React.useEffect(() => {
    ScrollToSection();
    disableScrollArrows();
  }, [data, error, loading]);

  const disableScrollArrows = () => {
    window.addEventListener(
      'keydown',
      function (e) {
        if (['ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
          e.preventDefault();
        }
      },
      false
    );
  };

  return (
    <Container className='mb-50'>
      {error && (
        <p>
          <i>Nepodarilo sa spojiť so serverom.</i>
        </p>
      )}
      {loading && (
        <p>
          <i>Načítavam...</i>
        </p>
      )}
      {data && !Array.isArray(data) && (
        <>
          <Row>
            <Col>
              <Heading
                isMain={true}
                text={data.lang[0].title.toUpperCase()}
                id='new'
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>{parseHTML(data.lang[0].content)}</Col>
          </Row>
          <Row>
            <Col>
              {data.published && (
                <p className='news-prev-date'>
                  {new Date(data.published).toLocaleDateString('cs-cz')}
                </p>
              )}
            </Col>
          </Row>
          {data.gallery.length > 0 && (
            <>
              <Row>
                <Col>
                  <Heading isMain={true} text={'GALERIE'} id='gallery' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      <Box className='lightbox-container'>
                        {data.gallery.map((item) => (
                          <a key={item.id} href={item.url ? item.url : ''}>
                            <img
                              src={
                                item.formats.thumbnail.url.length
                                  ? item.formats.thumbnail.url
                                  : item.url
                              }
                              alt={
                                item.alternativeText
                                  ? item.alternativeText
                                  : item.name
                              }
                              className='lightbox-item'
                              loading='lazy'
                            />
                          </a>
                        ))}
                      </Box>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
}
