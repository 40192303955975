import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Footer(): JSX.Element {
  return (
    <footer>
      <Container
        fluid
        className='imgbg footer pt-5 pb-4 text-white text-center'
      >
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <p>
                VUZ Slovakia, s. r. o.
                <br />
                Seberíniho 1
                <br />
                821 03 Bratislava – Ružinov
                <br />
                Slovenská republika
                <br />
              </p>
            </Col>
            <Col xs={12} md={4}>
              <p>
                <br />
                Copyright © {new Date().getFullYear()}
              </p>
            </Col>
            <Col xs={12} md={4}>
              <Link to='/gdpr' className='text-white'>
                Spracovanie osobných údajov
              </Link>
              <br />
              <Link to='/cookies' className='text-white'>
                Cookies a podmienky používania
              </Link>
              <br />
              <Link
                to=''
                className='text-white'
                aria-label='Zobraziť nastavenia cookies'
                data-cc='c-settings'
                aria-haspopup='dialog'
              >
                Nastavenie cookies
              </Link>
              <br />
              <p>IČO: 53156587</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
}
