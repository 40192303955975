import './App.css';
import './App-mobile.css';
import { theme } from './theme';
// MUI Examples - https://mui.com/getting-started/usage/
import { ThemeProvider } from '@mui/material';
import PageUp from './widgets/PageUp/PageUp';
import Menu from './components/Menu';
import Header from './components/Sections/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <PageUp />
        <Menu />
        <Header />
        <main>
          <Content />
        </main>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
