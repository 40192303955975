// React Router Examples - https://reactrouter.com/docs/en/v6/examples/basic
import { Routes, Route } from 'react-router-dom';

import Home from './Pages/Home';
import Cookies from './Pages/Cookies';
import Gdpr from './Pages/Gdpr';
import Career from './Pages/Career';
import Disclaimer from './Pages/Disclaimer';
import DisclaimerEN from './Pages/DisclaimerEN';
import News from './Pages/News';
import NewsDetails from './Pages/NewsDetails';

export default function Content(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path='/career' element={<Career />} />
      <Route path='/news' element={<News />} />
      <Route path='/news/:id' element={<NewsDetails />} />
      <Route path='/cookies' element={<Cookies />} />
      <Route path='/gdpr' element={<Gdpr />} />
      <Route path='/disclaimer' element={<Disclaimer />} />
      <Route path='/disclaimer-en' element={<DisclaimerEN />} />
      {/* If not url from above then */}
      <Route path='*' element={<Home />} />
    </Routes>
  );
}
