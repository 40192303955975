// React Boostrap Examples - https://react-bootstrap.github.io/
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';

export default function Cookies(): JSX.Element {
  return (
    <Container className='mb-50'>
      <Row>
        <Col>
          <Heading isMain={true} text='COOKIES' id='contact' />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h2>ZÁSADY POUŽÍVANIA SÚBOROV COOKIE</h2>
          <p>
            Používanie súborov cookie <br />
            Výzkumný ústav Železniční, a.s. (ďalej len „VÚZ“) používa súbory
            cookie na zlepšenie skúseností návštevníkov našich webových stránok
            vrátane webových stránok, ktoré sú prístupné prostredníctvom našich
            mobilných aplikácií, a zverejnil tieto zásady používania súborov
            cookie. Vysvetľuje, aké súbory cookie sa používajú a ako ich
            používame na našich webových stránkach. Ak nechcete dostávať súbory
            cookie pri prehliadaní našich webových stránok, môžete upraviť
            nastavenia svojho prehliadača alebo mobilného zariadenia tak, aby
            vás upozorňovali na prijatie súboru cookie, alebo sa môžete
            rozhodnúť obmedziť alebo zablokovať súbory cookie.
            <br />
            <br />
            Nižšie uvádzame, aké typy súborov cookie nájdete na webových
            stránkach VÚZ a akým spôsobom sa tieto súbory cookie používajú.
            Ďalšie informácie o našich zásadách ochrany osobných údajov nájdete
            na stránke Zásady ochrany osobných údajov.
            <br />
            <br />
            Súbory cookie sú malé súbory pozostávajúce z reťazca písmen a
            číslic, ktoré do vášho počítača ukladajú servery webových stránok.
            Umožňujú majiteľovi webových stránok odlíšiť vás od ostatných
            používateľov webových stránok. Súbory cookie nemôžu spúšťať kód ani
            slúžiť na šírenie vírusov, ani nemôžu poskytovať prístup na váš
            pevný disk. Nemôžeme čítať žiadne informácie na vašom pevnom disku,
            aj keď naň umiestnime náš súbor cookie.
            <br />
            <br />
            Údaje vygenerované súborom cookie na webových stránkach
            www.cdvuz.cz. sa môžu použiť na rôzne účely vrátane nasledujúcich:
            <br />
            <br />
            Pravidlo prísnej nevyhnutnosti
            <br />
            <br />
            Tieto súbory cookie sú kľúčom k tomu, aby ste sa mohli pohybovať po
            webových stránkach a používať ich funkcie, napríklad prístup k
            zabezpečeným oblastiam webových stránok.
            <br />
            <br />
            Výkon
            <br />
            Tieto súbory cookie zhromažďujú informácie o tom, ako návštevníci
            používajú webové stránky, napríklad ktoré stránky návštevníci
            navštevujú častejšie a či sa im zobrazujú chybové hlásenia.
            Používajú sa na zlepšenie fungovania webovej stránky v budúcich
            verziách.
            <br />
            <br />
            Funkcia
            <br />
            Súbory cookie nám umožňujú zapamätať si vaše voľby (používateľské
            meno, jazyk alebo región, v ktorom sa nachádzate) a poskytnúť vám
            personalizovanejšie služby.
            <br />
            <br />
            Zacielenie alebo reklama
            <br />
            Súbory cookie sa používajú na poskytovanie relevantnejšieho obsahu
            pre vás a vaše záujmy. Neumožňujeme tretím stranám umiestňovať
            reklamu na webové stránky, avšak nemôžeme kontrolovať inzerentov,
            ktorým poskytovatelia internetových služieb umožňujú umiestňovať
            reklamy počas prehliadania internetu.
            <br />
            <br />
            Výzkumný ústav Železniční, a.s. je zo zákona povinný získať váš
            súhlas pre všetky typy umiestnených súborov cookie, ktoré nespadajú
            do kategórie "nevyhnutne potrebných”.
            <br />
            <br />
            Viac informácií o tom, ako môžete spravovať súbory cookie, získate
            pomocou funkcie Pomocník prehliadača, nastavení mobilného zariadenia
            alebo na stránke www.aboutcookies.org*, kde nájdete podrobnejšie
            informácie o správe súborov cookie pre obľúbené prehliadače.
            Upozorňujeme, že niektoré časti našich webových stránok nemusia
            fungovať správne, ak zakážete súbory cookie.
            <br />
            <br />
            Používanie súborov cookie na stránkach spoločnosti Výzkumný ústav
            Železniční, a.s. Používame technológie online identifikácie -
            napríklad súbory cookie, webové majáky (tzv. „web beacons“) alebo
            pixely, aby sme vám mohli lepšie ponúknuť personalizovaný obsah.
            Používaním webových stránok alebo aplikácií súhlasíte s používaním
            týchto identifikačných technológií.
            <br />
            <br />
            Registrovaní používatelia
            <br />
            Tieto technológie používame na to, aby sme vám uľahčili navigáciu na
            webových stránkach a lepšie vám ponúkli personalizovaný obsah. Ak sa
            rozhodnete stať sa registrovaným používateľom (pozri nižšie), budeme
            používať súbory cookie ako nástroj, ktorý vám umožní registráciu a
            zapamätanie vašich preferencií. Výhody registrácie môžete využívať
            len vtedy, ak akceptujete potrebné súbory cookie vrátane tých, ktoré
            sa používajú v rámci registrácie.
            <br />
            <br />
            Analýza a štatistika stránok
            <br />
            Tieto technológie používame aj na zhromažďovanie sledovacích a
            štatistických údajov o používaní webových stránok. Zhromažďujeme
            napríklad informácie o návštevách stránok a navigácii, aby sme
            zistili, ktoré témy sú najzaujímavejšie a či používatelia môžu ľahko
            nájsť obsah. Zhromažďujeme aj údaje o tom, aký obsah sledujú alebo
            či sledujú celé videá, aby sme zistili, aký obsah je pre
            používateľov najzaujímavejší. Informácie o používaní používame aj na
            vytváranie rôznych správ o používaní webových stránok. Tieto správy
            obsahujú výlučne súhrnné údaje o používateľoch ako celku, nie o
            jednotlivcoch. Ak ste registrovaným používateľom, môžeme
            zhromažďovať aj informácie o vašich špecifických záujmoch vrátane
            informácií o článkoch, ktoré ste na stránke čítali, aby sme
            pochopili, aký obsah vás najviac zaujíma.
            <br />
            <br />
            Meranie účinnosti našich činností
            <br />
            Sme oprávnení používať technológie online identifikácie od
            marketingových partnerov, zo stránok tretích strán a platforiem
            sociálnych médií. Tieto technológie nám pomáhajú merať účinnosť
            našich marketingových a informačných kampaní a pochopiť, ako sa na
            webovej stránke objavujú návštevy na základe reklamy spoločnosti
            Výzkumný ústav Železniční, a. s. Tieto technológie používame na
            zhromažďovanie štatistických údajov o návštevách, ktoré interagujú s
            webovými stránkami a ďalším online obsahom spoločnosti Výzkumný
            ústav Železniční, a. s., a aby sme mohli merať účinnosť našich
            reklám a poskytovať návštevníkom relevantnejšie informácie.
            <br />
            <br />
            Správa súborov cookie na vašom zariadení
            <br />
            Súbory cookie môžete spravovať a kontrolovať prostredníctvom svojho
            prehliadača. Upozorňujeme, že odstránenie alebo zablokovanie súborov
            cookie môže ovplyvniť služby, ktoré používate, a niektoré funkcie
            nemusia byť následne dostupné.
            <br />
            <br />
            Riadenie súborov cookie z prehliadača
            <br />
            Väčšina prehliadačov umožňuje zobrazovať, spravovať, vymazávať a
            blokovať súbory cookie webových stránok. Upozorňujeme, že ak
            vymažete všetky súbory cookie, zrušia sa všetky vaše nastavenia
            vrátane možnosti zakázať súbory cookie, pretože táto funkcia sama o
            sebe vyžaduje, aby sa do vášho zariadenia uložil súbor cookie na
            toto zakázanie. Nižšie sú uvedené odkazy na pokyny na správu súborov
            cookie v bežných prehliadačoch.
            <ul>
              <li>Google Chrome</li>
              <li>Mozilla Firefox</li>
              <li>MacOS Safari</li>
              <li>Microsoft Internet Explorer</li>
            </ul>
            Informácie o ďalších prehliadačoch a typoch zariadení nájdete na
            stránkach{' '}
            <a
              href='http://www.aboutcookies.org/'
              rel='noreferrer'
              target='_blank'
            >
              http://www.aboutcookies.org/
            </a>{' '}
            alebo{' '}
            <a
              href='http://www.cookiecentral.com/faq/'
              rel='noreferrer'
              target='_blank'
            >
              http://www.cookiecentral.com/faq/
            </a>
            .
            <br />
            <br />
            Správa analytických súborov cookie
            <br />
            Môžete sa rozhodnúť zakázať zaznamenávanie anonymizovanej histórie
            prehliadania pomocou analytických súborov cookie na našich webových
            stránkach alebo v našich aplikáciách. Používame poskytovateľov
            služieb uvedených nižšie a viac informácií o ich zásadách ochrany
            osobných údajov a o tom, ako zakázať ich analytické súbory cookie,
            získate kliknutím na nasledujúce odkazy:
            <br />
            Google Analytics
            <br />
            <br />
            Informácie o súboroch cookie
            <br />
            Nasledujúca tabuľka opisuje typy, kategórie a účely súborov cookie
            na týchto webových stránkach. Používaním týchto webových stránok
            súhlasíte s tým, že môžeme do vášho počítača/zariadenia umiestniť
            nasledujúce súbory cookie na uvedené účely.
            <br />
            <br />
            Typy súborov cookie:
            <br />
            „Dočasné“ (session) súbory cookie: tieto súbory cookie zostávajú vo
            vašom prehliadači len počas trvania relácie, t. j. kým neopustíte
            webové stránky. „Trvalé“ (persistent) súbory cookie: tieto súbory
            cookie zostávajú vo vašom prehliadači aj po skončení relácie (ak ich
            aktívne neodstránite). Kategórie súborov cookie:
            <br />
            <br />
            „Nevyhnutne potrebné“ (strictly necessary) súbory cookie: tieto
            súbory cookie sú nevyhnutné na fungovanie webovej stránky a nemožno
            ich vypnúť bez zablokovania funkcií webovej stránky. Zvyčajne sa
            nastavujú ako reakcia na vaše činnosti na stránke, napríklad
            vyplnenie formulárov, nastavenie preferencií alebo prihlásenie.
            „Výkonové“ (performance) cookies: Tieto súbory cookie nám umožňujú
            zhromažďovať analytické údaje na zlepšenie výkonu a funkčnosti
            našich stránok. Tieto analytické údaje môžu zahŕňať meranie
            obľúbenosti stránky, bežné spôsoby pohybu ľudí po stránke a
            frekvenciu používania určitých funkcií. Zvyčajne údaje na účely
            revízií agregujeme, ale v niektorých prípadoch môžeme zhromažďovať
            informácie o obsahu, ktorý ste navštívili, aby sme pochopili, čo vás
            najviac zaujíma. „Personalizačné“ (customization) súbory cookie:
            tieto súbory cookie nám pomáhajú pochopiť, ako efektívne sú naše
            marketingové kampane, a zlepšiť používateľské prostredie
            prostredníctvom personalizácie. „Reklamné“ (advertising) súbory
            cookie: Výzkumný ústav Železniční, a.s. môže umiestňovať reklamy na
            stránky, ktoré nie sú vlastnené alebo prevádzkované spoločnosťou
            Výzkumný ústav Železniční, a.s., s cieľom propagovať naše služby,
            články a podujatia. Tieto súbory cookie sa používajú na to, aby boli
            reklamné správy relevantné pre vás a vaše záujmy. Plnia aj také
            funkcie, ako je zabránenie opakovanému zobrazovaniu reklám tomu
            istému používateľovi. Tieto reklamy sa používajú len na informovanie
            o relevantných ponukách. Výzkumný ústav Železniční, a.s. nepredáva
            ani inak nezverejňuje vaše údaje žiadnej tretej strane.
            <br />
            <br />
            Poskytovateľ Názov Účel Typ Trvanie
            <br />
            Google Analytics _gat Tento názov súboru cookie je spojený so
            službou Google Universal Analytics a podľa dokumentácie spoločnosti
            Google sa používa na zrýchlenie počtu požiadaviek - obmedzuje zber
            údajov na stránkach s vysokou návštevnosťou. Vyprší po 10 minútach.
            Vlastné, Dočasné 1 minúta
            <br />
            <br />
            Google Analytics _gid Tento súbor cookie ukladá jedinečný
            identifikátor relácie používateľa. Vlastné a Tretích strán, Dočasné
            24 hodín Google Analytics _ga Tento súbor cookie sa zvyčajne zapíše
            do vášho prehliadača pri prvej návšteve webových stránok. Ak bol
            súbor cookie vymazaný prevádzkovateľom prehliadača a prehliadač
            následne navštívi stránku VÚZ a.s..cz, zapíše sa nový súbor cookie s
            iným jedinečným ID. Vo väčšine prípadov sa tento súbor cookie
            používa na zistenie, či je návštevník jednorazovým návštevníkom
            webových stránok alebo či je opakovaným návštevníkom, a aktualizuje
            sa po každom zobrazení stránky. Okrem toho je tento súbor cookie
            vybavený jedinečným identifikátorom, ktorý služba Google Analytics
            používa na zabezpečenie platnosti a dostupnosti súboru cookie ako
            dodatočného prostriedku zabezpečenia. Vlastné a Tretích strán,
            Trvalé 2 roky
            <br />
            Google Analytics __utmb Tento súbor cookie sa používa na
            identifikáciu novej relácie/návštevy. Vlastné 30 minút od
            nastavenia/aktualizácie Google uid Ide o súbor cookie UserID
            spoločnosti Google, ktorý sa používa na sledovanie používateľov v
            rôznych častiach webových stránok. Tretích strán, Trvalé Ak máte
            akékoľvek otázky týkajúce sa používania súborov cookie na našich
            webových stránkach, môžete kontaktovať{' '}
            <a href='mailto:gdpr@cdvuz.cz?subject=Dotaz'>gdpr@cdvuz.cz</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
