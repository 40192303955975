// React Boostrap Examples - https://react-bootstrap.github.io/
import { Divider } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Heading from '../HeadingBox/Headingbox';

export default function Disclaimer(): JSX.Element {
  return (
    <Container className='mb-50'>
      <Row>
        <Col>
          <Heading isMain={true} text='DISCLAIMER' id='contact' />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <h2>Disclaimer</h2>
          <p>
            Informácie uvedené v tomto e-maile sú dôverné. Spoločnosť Výzkumný
            Ústav Železniční, a.s. informuje príjemcu tejto elektronickej
            správy, že týmto spôsobom nerobí ani neprijíma žiadny návrh na
            uzavretie akejkoľvek zmluvy.
            <br />
            <br />
            Obchodná spoločnosť Výzkumný Ústav Železniční, a.s. zároveň
            informuje príjemcu tejto elektronickej správy, že týmto spôsobom
            nerobí a ani neprijíma žiadnu ponuku na zmenu alebo ukončenie
            zmluvného vzťahu. Spoločnosť Výzkumný Ústav Železniční, a.s. môže
            vstupovať do zmluvných vzťahov alebo ich meniť či ukončovať len
            prostredníctvom osôb oprávnených konať v jej mene, avšak ani tieto
            osoby nemôžu vstupovať do zmluvných vzťahov alebo ich meniť či
            ukončovať elektronickým podaním, s výnimkou prípadu prevodu
            originálu podpísaného dokumentu do elektronickej podoby (skenu),
            pričom táto podoba bude výslovne potvrdená ako sken originálu
            dokumentu v texte sprievodného e-mailu a vopred vzájomne odsúhlasená
            účastníkmi zmluvného vzťahu.
            <br />
            <br />
            Túto správu si preto nemožno vykladať ako odôvodnené očakávanie
            uzavretia, ukončenia alebo zmeny zmluvy, s výnimkou prípadov
            uvedených v predchádzajúcej vete. Prijatie návrhu na uzavretie
            zmluvy s dodatkom alebo zmenou je vylúčené podľa § 1740 ods. 3
            zákona č. 89/2012 Z. z., Občiansky zákonník.
            <br />
            <br />
            Ochranu osobných údajov zabezpečuje spoločnosť Výzkumný Ústav
            Železniční, a.s. v súlade s platnými právnymi predpismi Európskej
            únie a Českej republiky.
            <br />
            Podrobnosti nájdete v dokumente "ZÁSADY OCHRANY OSOBNÝCH ÚDAJOV",
            ktorý je k dispozícii na adrese{' '}
            <a href='https://vuzsk.sk/gdpr' target='_blank' rel='noreferrer'>
              https://vuzsk.sk/gdpr
            </a>
          </p>
        </Col>
        <Col sm={4} style={{ textAlign: 'right' }}>
          <Divider />
          <Link to='/disclaimer-en' rel='noreferrer'>
            English
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
