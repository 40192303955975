// React Boostrap Examples - https://react-bootstrap.github.io/
import { Divider } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Heading from '../HeadingBox/Headingbox';
import privacy from '../../img/privacy_policy.pdf';

export default function DisclaimerEN(): JSX.Element {
  return (
    <Container className='mb-50'>
      <Row>
        <Col>
          <Heading isMain={true} text='DISCLAIMER-EN' id='contact' />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <h2>Disclaimer</h2>
          <p>
            The information in this mail is considered as confidential. The
            business company, Výzkumný Ústav Železniční, a.s., notifies the
            recipient of this electronic message that such message fails to
            represent either placing or acceptance of any offer to conclude any
            contract whatsoever. The business company, Výzkumný Ústav
            Železniční, a.s., also notifies the recipient of this electronic
            message that this message fails to represent either placing or
            acceptance of an offer to alter or terminate any contractual
            relationship whatsoever.
            <br />
            <br />
            Výzkumný Ústav Železniční, a.s. may enter a contractual relationship
            or make any changes thereto or terminate thereof only by persons
            authorised to act on behalf thereof, however, even such persons are
            prohibited from entering into a contractual relationship or change
            or terminate a contractual relationship by electronic filing,
            excluding the case when an original signed document is transformed
            into an electronic document (scan), and such electronic document
            shall be expressly confirmed as a scan of the original document in
            the text of an accompanying email and mutually agreed on in advance
            by the parties to the contract.
            <br />
            <br />
            Thus, this message may not be understood as a reasonable expectation
            of a contract conclusion, termination or alteration, excluding the
            cases described in the previous sentence. In compliance with
            provisions of sec. 1740 par.3 Act no. 89/2012 Coll., Civil Code,
            adoption of a proposal for the conclusion of a contract including a
            supplement or deviation is excluded.
            <br />
            <br />
            Personal Data Protection Business Company Výzkumný Ústav Železniční,
            a.s. in accordance with applicable European Union and Czech Republic
            legislation. For details, refer to the „PRIVACY POLICY“ available{' '}
            <a href={privacy} target='_blank' rel='noreferrer'>
              here
            </a>
            .
          </p>
        </Col>
        <Col sm={4} style={{ textAlign: 'right' }}>
          <Divider />
          <Link to='/disclaimer' rel='noreferrer'>
            Slovensky
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
