// React Boostrap Examples - https://react-bootstrap.github.io/
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../HeadingBox/Headingbox';
import React from 'react';
import useFetch from '../../hooks/fetchNews';
import ScrollToSection from '../ScrollToSection';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import NewsPreview from '../../img/logo-vuz-sk.svg';

export default function News(): JSX.Element {
  const { data, error, loading } = useFetch(
    'https://wr.cdvuz.cz/storiessk?_sort=published:DESC'
  );

  React.useEffect(() => {
    ScrollToSection();
  }, [data, error, loading]);

  return (
    <Container className='mb-50'>
      <Row>
        <Col>
          <Heading isMain={true} text='NOVINKY' id='new' />
        </Col>
      </Row>
      <Row>
        <Col>
          {error && (
            <p>
              <i>Nepodarilo sa spojiť so serverom.</i>
            </p>
          )}
          {loading && (
            <p>
              <i>Načítavam položky...</i>
            </p>
          )}
          {Array.isArray(data) &&
            data.map((item) => (
              <Box className='news-prev-container' key={item.id}>
                <Link to={`/news/${item.id}#new`}>
                  <img
                    className={`news-prev-img ${
                      item.image ? '' : 'news-prev-placeholder'
                    }`}
                    src={item.image ? item.image.url : NewsPreview}
                    alt={
                      item.image && item.image.alternativeText
                        ? item.image.alternativeText
                        : 'Náhľad'
                    }
                    loading='lazy'
                  />
                </Link>
                <Box className='news-prev-content'>
                  <h3>
                    <Link to={`/news/${item.id}#new`}>
                      {item.lang[0].title}
                    </Link>
                  </h3>
                  <p className='news-prev-date'>
                    {new Date(item.published).toLocaleDateString('cs-cz')}
                  </p>
                </Box>
              </Box>
            ))}
        </Col>
      </Row>
    </Container>
  );
}
